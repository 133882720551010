import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "components/header";
import Medicamentos from "components/medicamentos";
import Medico from "components/medico";
import Paciente from "components/paciente";
import { IReceta, IRecetaWithPrescription } from "interfaces/recetas";
import React, { useEffect, useCallback } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import receta from "services/receta";
import { ContainerReceta } from "./styles";

const DetalleReceta = () => {
  const [detailRecipe, setDetailRecipe] = useState<IReceta | undefined | null>(
    null
  );
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const [error, setError] = useState(false);
  
  useEffect(() => {
    getDetailRecipe();
  }, []);

  const getDetailRecipe = useCallback(async () => {
    try {
      const { data } = await receta.getDetailRecipe(params.hash!);
      if(data && data.medicamentos){
        setDetailRecipe(data)
      }else{
        setDetailRecipe(null);
        setError(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setDetailRecipe(null);
      setError(true);
      return error;
    }
  }, [params.hash]);

  return (
    <Box>
      <ContainerReceta>
        {loading && (
          <div className="ContainerProgress">
            <CircularProgress />
          </div>
        )}
        {!error ? 
        <>
          {!loading && (
            <Header
              emisor={detailRecipe ? detailRecipe.emisor : ""}
              fechaEmision={
                detailRecipe && detailRecipe.fechaEmision !== undefined
                  ? detailRecipe.fechaEmision
                  : ""
              }
              nroReceta={detailRecipe ? detailRecipe.numeroReceta : 0}
            />
          )}
          {!loading && detailRecipe && detailRecipe.medico && <Medico medico={detailRecipe.medico} />}
          {!loading && detailRecipe && (
            <Paciente
              paciente={detailRecipe.paciente}
              diagnostico={detailRecipe.diagnostico}
              tratamiento={detailRecipe.tratamiento}
              financiador={detailRecipe.financiador}
              codDiagnostico={detailRecipe.codDiagnostico}
            />
          )}
         {!loading && detailRecipe && (
            <Medicamentos medicamentos={detailRecipe.medicamentos} imprimirDiagnostico={detailRecipe.imprimirDiagnostico} />
          )}
        </> : <Header
              emisor={"Desconocido"}
              fechaEmision={""}
              nroReceta={0}
            />
        }
      </ContainerReceta>
    </Box>
  );
};

export default DetalleReceta;
