import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DetalleReceta from "pages/DetalleReceta";
import DetalleRecetaPrescripcion from "pages/DetalleRecetaPrescripcion";

export default function Routing() {
  return (
    <BrowserRouter
      basename={process.env.PUBLIC_URL ? process.env.PUBLIC_URL : ""}
    >
      <Routes>
        <Route exact path="/:hash" element={<DetalleReceta />} />
        <Route exact path="/prescripcion/:hash" element={<DetalleRecetaPrescripcion />} />
      </Routes>
    </BrowserRouter>
  );
}
