import styled from "styled-components";


const ContainerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;

  p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 13.6px;
      color: #262626;
  }
  .Title {
    font-weight: 700;
    letter-spacing: 1px;
  }
  .Tag {
    font-weight: 700;
  }

  .MedicamentoContent{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 12px;

    .MedicamentoPrescipto {
        
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        .LineaHeader {
            background: #EFEFEF;
            width: 100%;
            padding: 3px 10px;

            .Subtitle {
                font-weight: 600;
                letter-spacing: 0.5px;
            }
        };
        .LineaDescripcion {
            width: 100%;
            padding: 2px 15px;
        };

        .GroupLineaDescripcion {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          gap: 5px;
          width: 100%;
        };

        .BodyMed {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;
          width: 100%
        };

        .BarStatus {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          width: 100%;
          margin: "15px 0px";
          justify-content: center;

          .Status {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 5px;

            p {
                font-family: "Montserrat";
                font-style: normal;
                font-weight: 500;
                font-size: 12.6px;
                color: #262626;
            };

            .Indicador {
              width: 10px;
              height: 10px;
              border-radius: 20px;
            }
          }
        }
    }
  }
`;

export { ContainerSection };