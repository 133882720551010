import styled from "styled-components";


const ContainerSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 10px;
  

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
  }

  p {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 13.6px;
      color: #262626;
  }
  .Title {
    font-weight: 900;
    letter-spacing: 2px;
  }
  .Tag {
    font-weight: 700;
  }
`;

export { ContainerSection };