import styled from "styled-components";

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 100%;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;

  .DataRecipe {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    gap: 15px;
    width: 100%;

    .DataRecipeVerificacion {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 5px;
      gap: 5px;
      align-items: center;
    }
  }
  p {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 500;
    font-size: 13.6px;
    color: #262626;
  }
  .Title {
    font-weight: 900;
    letter-spacing: 2px;
  }
  .Tag {
    font-weight: 700;
  }
`;

const shareStyles = {
  shareBox: {
    with: "100%",
    height: "25vh",
    padding: "20px",
  },
  iconSize: "70px",
  copyButton: {
    width: "70px",
    height: "100px",
    color: "gray",
  },
  boxCopyButton: {
    width: "70px",
    height: "100px",
  },
};

export { ContentHeader, shareStyles };
