import { AxiosResponse } from "axios";
import { IReceta, IRecetaWithPrescription } from "interfaces/recetas";

import ServiceBase from "../ServiceBase";

const BASE_URL = process.env.REACT_APP_QR_API_URL;
const RECETA_ENDPOINT = process.env.REACT_APP_ENDPOINT_DECRYPTER;
const REACT_APP_ENDPOINT_WITH_PRESCRIPTION =
  process.env.REACT_APP_ENDPOINT_WITH_PRESCRIPTION;

class RecetaService extends ServiceBase {
  constructor() {
    super(BASE_URL!);
  }

  getDetailRecipe = (hash: string) =>
    this.client.get<ResponseType, AxiosResponse<IReceta>>(
      RECETA_ENDPOINT! + "", {params: {hashedRecipe: hash}}
    );
  getDetailWithPrescripcion = (hash: string) =>
    this.client.get<ResponseType, AxiosResponse<IRecetaWithPrescription>>(
      REACT_APP_ENDPOINT_WITH_PRESCRIPTION! + "", {params: {hashedRecipe: hash}}
    );
}

export default new RecetaService();
