import styled from "styled-components";


const ContainerReceta = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 5px 20%;

  @media (max-width: 768px) {
  margin: 5px 5px;
  }

  .ContainerProgress {    
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export { ContainerReceta };