import axios, { AxiosInstance } from "axios";

export default abstract class ServiceBase {
  protected readonly client: AxiosInstance;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    });

    this.client.interceptors.request.use((config) => {
      config.headers!.Authorization = "Bearer " + localStorage.getItem("token");

      return config;
    });
      
    this.client.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        return error;
      }
    );
  }
}
