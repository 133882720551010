import React from "react";
import { ContainerSection } from "./styles";
import { PropsMedicoRecipe } from "./interface";

const Medico = ({medico}: PropsMedicoRecipe) => {
  return (
    <ContainerSection>
      <div className="DataMedico">
        <p>
          <span className="Tag">Médico:</span> {medico.nombre} {medico.apellido}
        </p>
        <p>
          <span className="Tag">Matrícula:</span> {medico.matricula.tipo}{" "}
          {medico.matricula.numero} {medico.matricula.tipo !== "MN" ? "- " + medico.matricula.provincia : ''}
        </p>
        <p>
          <span className="Tag">Especialidad:</span> {medico.especialidad}
        </p>
      </div>
    </ContainerSection>
  );
};

export default Medico;
